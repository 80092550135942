<template>
  <section>      
    <router-view :organisation="organisation" @updatedInformation="updatedInformation"></router-view>
  </section>
</template>

<script>
export default {
  props: ["organisation"],
  data() {
    return {
    };
  },
  methods: {    
    updatedInformation: function(updatedInformation) {      
      this.$emit("updatedInformation", updatedInformation);
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
  }
};
</script>
<style></style>